/* global Cookies, lightbox */
/* eslint-env jquery, cookie */

'use strict';

jQuery( function( $ ) {
	$( '.slider-main' ).slick( {
		arrows: true,
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		pauseOnHover: false,
		responsive: [
			{
			  breakpoint: 576,
			  settings: {
				arrows: false,
				dots: true,
			  }
			},
		  ]
	} );

	$( '.slider-products' ).slick( {
		mobileFirst: true,
		arrows: true,
		dots: true,
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		autoplay: false,
		responsive: [
			{
			  breakpoint: 768,
			  settings: {
				dots: false,
				slidesToShow: 3,
			  }
			},
			{
				breakpoint: 992,
				settings: {
				  dots: false,
				  slidesToShow: 4,
				}
			  },
		  ]
	} );

	$( '.slider-instagram' ).slick( {
		mobileFirst: true,
		arrows: true,
		dots: true,
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		autoplay: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
				  dots: false,
				  slidesToShow: 4,
				}
			},
			{
			  breakpoint: 992,
			  settings: {
				dots: false,
				slidesToShow: 5,
			  }
			},
		  ]
	} );

	$( document ).on( 'click', '#showFilters', function( event ) {
		event.preventDefault();
		$( this ).toggleClass( 'btn-filter--active' );
		$( '#shopFilters' ).toggleClass( 'filters__wrapper--visible' );
	} );

	$( document ).on( 'click', '.btn-close', function( event ) {
		event.preventDefault();
		$( this ).parent().fadeOut();
	} );

	$( document ).on( 'click', '.btn-loader', function( event ) {
		event.preventDefault();
		$( this ).toggleClass( 'btn-loader--active' );
	} );

	lightbox.option( {
		'disableScrolling': true,
		'showImageNumberLabel': false,
	} );

	$( document ).on( 'click', '#decQty', function() {
		const val = parseInt( $( '#quantity' ).val() );
		const min = parseInt( $( '#quantity' ).attr('min') );
		if ( val > min ) {
			$( '#quantity' ).val( ( val - 1 ) );
		}
	} );

	$( document ).on( 'click', '#incQty', function() {
		const val = parseInt( $( '#quantity' ).val() );
		const max = parseInt( $( '#quantity' ).attr('max') );
		if ( val < max ) {
			$( '#quantity' ).val( ( val + 1 ) );
		}
	} );

	$( document ).on( 'click', '.product-info__title', function( event ) {
		event.preventDefault();

	    const element = $( this );

	    if ( element.hasClass( 'product-info__title--open' ) ) {
	        element.removeClass( 'product-info__title--open' );
	        element.next().slideUp( 350 );
	    } else {
			if ( $( '.product-info__title--open' ).length ) {
				$('.product-info__title--open').next().slideUp('350');
				$('.product-info__title--open').removeClass( 'product-info__title--open' );
			}
			element.addClass( 'product-info__title--open' );
			element.next().slideToggle( 350 );
		}
	} );

	$( '.slider-thumbs' ).slick( {
		mobileFirst: true,
		dots: false,
		infinite: false,
		speed: 300,
		arrows: false,
		vertical: false,
		horizontal: true,
		verticalSwiping: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.slider-images',
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 992,
				settings: {
					arrows: true,
					vertical: true,
					horizontal: false,
					slidesToShow: 4,
				}
			},
			{
			  breakpoint: 1400,
			  settings: {
				arrows: true,
					vertical: true,
					horizontal: false,
					slidesToShow: 5,
			  }
			}
		  ]
	} );

	$('.slider-images').slick( {
		mobileFirst: true,
		infinite: false,
		arrows: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
		asNavFor: '.slider-thumbs',
		responsive: [
			{
			  breakpoint: 992,
			  settings: {
				arrows: true,
			  }
			},
		  ]
	 } );

	$( document ).on( 'click', '#showOverview', function( event ) {
		event.preventDefault();
		$( '#cartOverview' ).addClass( 'cart-overview--visible' );
	} );

	$( document ).on( 'click', '#hideOverview', function( event ) {
		event.preventDefault();
		$( '#cartOverview' ).removeClass( 'cart-overview--visible' );
	} );

	$( document ).on( 'click', '#toggleSearch', function( event ) {
		event.preventDefault();
		$( this ).toggleClass( 'nav-shop__item-link--close');
		$( '#searchForm' ).toggleClass( 'site-search--visible');
	} );

	$( document ).on( 'click', '#showModal', function( event ) {
		event.preventDefault();
		$( '#modal' ).addClass( 'modal--visible');
	} );

	$( document ).on( 'click', '#hideModal', function( event ) {
		event.preventDefault();
		$( '#modal' ).removeClass( 'modal--visible');
	} );

	$( document ).on( 'click', '#showMobileMenu', function( event ) {
		event.preventDefault();
		$( '#navigationMobile' ).addClass( 'cart-overview--visible' );
	} );

	$( document ).on( 'click', '#hideMobileMenu', function( event ) {
		event.preventDefault();
		$( '#navigationMobile' ).removeClass( 'cart-overview--visible' );
	} );


	$( document ).on( 'click', '.nav-mobile__link--parent', function( e ) {
		e.preventDefault();
		const navItem = $( this ).parent();

		if ( navItem.hasClass( 'nav-mobile__item--visible' ) ) {
			navItem.children( '.nav-mobile__dropdown' ).slideToggle();
			navItem.removeClass( 'nav-mobile__item--visible' );
		} else {
			navItem.children( '.nav-mobile__dropdown' ).slideToggle();
			navItem.addClass( 'nav-mobile__item--visible' );
		}
	} );

	const largeOnly = window.matchMedia("(min-width: 992px)");

	if ( largeOnly.matches ) {
		$( '.slider-images__slide' ).zoom();
	}

} );
